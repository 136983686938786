import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./App.scss";
import Home from "./pages/Home";
import Error404 from "./pages/Error404";
import Dashboard from "./pages/Dashboard";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import MyProfile from "./pages/MyProfile";
import 'react-loading-skeleton/dist/skeleton.css'
import AddNewOrder from "./pages/AddNewOrder";
import AllOrder from "./pages/AllOrder";
import Invoice from "./pages/Invoice";
import CallFollowUp from "./pages/CallFollowUp";
import AddMemberOrder from "./pages/AddMemberOrder";


function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/my-profile" element={<MyProfile />} />
          <Route path="/call-follow-up" element={<CallFollowUp />} />
          <Route path="/member-new-order" element={<AddNewOrder />} />
          <Route path="/all-orders" element={<AllOrder />} />
          <Route path="/invoice/:slug" element={<Invoice />} />
          <Route path="/add-member-order/:slug" element={<AddMemberOrder />} />
          <Route path="*" element={<Error404 />} />
        </Routes>
      </BrowserRouter>
      <ToastContainer position="bottom-center" />
    </>
  );
}

export default App;
